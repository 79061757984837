<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->
    <b-card no-body>
    
      <b-card-body>
        <b-row>
         

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="suppliers"
              label="name"
              class="w-100"
              placeholder="Supplier"
              @input="filterTable"
              v-model="supplierData"
              :clearable="false"
            >
            <template #selected-option="{ name }">
                <span v-if="name && name.length < 23">{{name}}</span>
                <span v-if="name && name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
              </template>
            </v-select>
          </b-col>

          

          <b-col 
           cols="12"
           md="8"
           class="mb-md-0 mobile_tab_max_width_flex"
          >

            <div class="d-flex align-items-center justify-content-end mobile_tab_display_block">

              <b-button
                
                variant="danger"
                class="mobile-margin-bottom mr-1"
                @click="resetFilter()"
              >
                <span class="text-nowrap">Reset</span>
              </b-button>

              <b-button
                
                variant="warning"
                class="mobile-margin-bottom mr-1"
                :to="{name:'add-supplier-category',params:{supplier_id:supplierData != null ? supplierData._id : 'all-suppliers'}}"
              >
                <span class="text-nowrap">Add</span>
              </b-button>
            </div>
          </b-col>
          
        </b-row>
      </b-card-body>
    </b-card>

    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                placeholder="Search..."
                @input="filterTable"
                autocomplete="off"
              />
              
              
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        

        <template #cell(supplier)="items">
           
           <span class="wordBreak">{{ items.item.supplier.name | capitalize}}</span>
        
       </template>

        <!-- Column: Role -->
        <template #cell(name)="items">
           
            <span class="wordBreak" >{{ items.item.name | capitalize}}</span>
         
        </template>

        

        
        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <span >{{sitedateTime(items.item.created_at,'DD MMM YYYY','HH:mm')}}</span>
         
        </template>

        <template #cell(actions)="items">

          <b-button
            size="sm"
            variant="warning"
            class="mr-1 mb-1"
            :to="{ name: 'supplier-sub-category', params: { supplier_id: items.item.supplier._id, category_id: items.item._id } }"
          >
            View Sub Categories
          </b-button>

          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            

            <b-dropdown-item :to="{ name: 'edit-supplier-category', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteItem(items.item)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            

            
          </b-dropdown>  -->
      <div>
          <b-link :to="{ name: 'edit-supplier-category', params: { id: items.item._id } }"
            v-b-tooltip.hover.v-warning
            title="Edit"
            variant="outline-warning"
          >
              <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
              
          </b-link>

          <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
          </b-link>

          <b-link  @click="deleteItem(items.item)" v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning">
            <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
          </b-link>
      </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,VBTooltip,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {

        tableColumns: [
          
          
          { key: 'supplier', label: 'Supplier Name', sortable: false , thStyle:  {width: '20%'}},
          { key: 'name', label: 'Category Name', sortable: true , thStyle:  {width: '30%'}},
          { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '10%'}},
          { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '15%'}},
          { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '25%'}},
        ],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
       
       
        suppliers:[],
        supplierData:null,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:0,
        to:0,
    }
  },
  methods : {
    
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Inventory'
      },{
        to:null,
        text: 'Settings'
      },{
        to:{name:'suppliers'},
        text: 'Suppliers Info',
      },{
        to:null,
        text: 'Categories',
        active:true
      }];
      return item;
    },
    
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     
                     supplier:this.supplierData ? this.supplierData._id : null
                   },
                   api: '/api/suppliers-categories-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Category Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Category Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-supplier-category-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.errorAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },
    deleteItem(item){

      if (item.item == true) {

        Swal.fire({
          title: 'Permission Required',
          text: 'Unable to delete the Supplier. Please contact EL Taskforce Support',
          icon: 'info',
          position: 'center',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'No'
        })


      } else {

        var msg = 'Are you sure want to delete this record?';
        var message = 'Category Deleted Successfully.';

        Swal.fire({
          title: 'Please Confirm',
          text: msg,
          icon: 'warning',
          position: 'top-center',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
        .then(result => {
          if (result.value) {
            return this.$store.dispatch(POST_API, {
              data: {
                id: item._id,
                status: 'deleted'
              },
              api: "/api/change-supplier-category-status",
            })
              .then(() => {
                if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.errorAlert();
                } else {

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1500
                  })

                  this.$refs.refUserListTable.refresh();
                }
              });
          }
        })
        .catch(err => {

        })
        
      }
      
    },
    getSuppliers(){
      return this.$store.dispatch(POST_API, {
         data:{
              role:this.$store.getters.currentUser.role,
              site:this.siteData ? this.siteData._id : null,
         },
         api: '/api/all-suppliers'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.suppliers = this.$store.getters.getResults.data;
              var obj = {
                  _id:'all-suppliers',
                  name:'All Suppliers'
              }
              this.suppliers.unshift(obj);
              return this.suppliers;
          }
      });
    },
    updateSite(){
      this.getSuppliers();
      this.supplierData = {
        _id: 'all-suppliers',
        name: 'All Suppliers'
      };

      this.filterTable();

    },
    async setParamsValue(){
      await this.getSuppliers();
      
      if (this.$route.params.supplier_id != '') {
        var supplierData = this.suppliers.filter(item => {return item._id == this.$route.params.supplier_id});  
        this.supplierData = supplierData.length > 0 ? supplierData[0] : {_id: this.$route.params.supplier_id,name:'Supplier' } ;  
      }

      this.filterTable();

    },
    resetFilter(){
      this.updateSite();
    }
  },
  mounted(){
    this.setParamsValue();
  }
  
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
